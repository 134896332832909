import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ScraperComponent } from './components/scraper/scraper.component';
import { FormsModule } from '@angular/forms';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import { AgmCoreModule } from '@agm/core';
import { AgmDirectionModule } from 'agm-direction';
import { GeocodeService } from './services/geocode/geocode.service';
import { CopyToClipboardDirective } from './directives/copy-to-clipboard/copy-to-clipboard.directives';
import { AngularFireModule } from '@angular/fire';
import { AngularFirestore } from '@angular/fire/firestore';

@NgModule({
  declarations: [
    AppComponent,
    ScraperComponent,
    CopyToClipboardDirective
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    BrowserAnimationsModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyAhQKWoOEKCQWCuExvFPTGsnql9Y3-NFBA'
    }),
    AgmDirectionModule,
    AngularFireModule.initializeApp({
      apiKey: "AIzaSyD7TlzoY935PN3C8BR11ZghqJVGGKdFukw",
      authDomain: "miniging-trips.firebaseapp.com",
      projectId: "miniging-trips",
      storageBucket: "miniging-trips.appspot.com",
      messagingSenderId: "728394863359",
      appId: "1:728394863359:web:2df12fe723a840950832ca",
      measurementId: "G-SKLJHR79HD"
    })
  ],
  providers: [GeocodeService,AngularFirestore],
  bootstrap: [AppComponent]
})
export class AppModule { }
