import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as moment from "moment-timezone";
import { Observable } from 'rxjs';
import { urls } from "src/app/utils/url-utils";

@Injectable({
  providedIn: 'root'
})
export class ScraperService {
  constructor(private http: HttpClient) { }

  getData(scheduleTime): Observable<any> {
    return this.http.get(urls.SERVER_API_URL + urls.GET_TRIPS_URL, {params: new HttpParams().set('scheduletime', scheduleTime)});
  }


  getCoordsFromServer(data): Observable<any> {
    return this.http.post("https://www.devserver.nemtservices.com/miningAddressGeoCode",{"address":data},{headers: new HttpHeaders().set('Content-Type', 'application/json' ) });
  }

  downloadFile(): Observable<any> {
    return this.http.get(urls.SERVER_API_URL + urls.GET_EXCEL_FILE_URL);
  }
}
