import { Directive, ElementRef, HostListener, Renderer2 } from '@angular/core';

@Directive({
  selector: '[copy-to-clipboard]'
})
export class CopyToClipboardDirective {
  private element: HTMLInputElement;
  tooltip: HTMLElement;

  @HostListener("mouseover") onMouseEnter() {
    // this.showTooltip();
  }


  @HostListener("mouseleave") onMouseLeave() {
    // console.log('m leave')
    // this.renderer.addClass(this.tooltip, "tooltip_show");
     // on mouse over it will remove the opacity
  }

  @HostListener('click', ['$event']) onClick($event) {
    console.log('Copy to Clipboard', this.element.innerText);

    var textArea = document.createElement("textarea");
    // Hide the textarea from actually showing
    textArea.style.position = 'fixed';
    textArea.style.top = '-999px';
    textArea.style.left = '-999px';
    textArea.style.width = '2em';
    textArea.style.height = '2em';
    textArea.style.padding = '0';
    textArea.style.border = 'none';
    textArea.style.outline = 'none';
    textArea.style.boxShadow = 'none';
    textArea.style.background = 'transparent';

    // Set the texarea's content to our value defined in our [text-copy] attribute
    textArea.value = this.element.innerText;
    document.body.appendChild(textArea);

    // This will select the textarea
    textArea.select();
    try {
      // Most modern browsers support execCommand('copy'|'cut'|'paste'), if it doesn't it should throw an error
      var successful = document.execCommand('copy');
      var msg = successful ? 'successful' : 'unsuccessful';
      // this.element.style.color = 'black';
      // Let the user know the text has been copied, e.g toast, alert etc.
      console.log(msg);
    } catch (err) {
      // Tell the user copying is not supported and give alternative, e.g alert window with the text to copy
      console.log('unable to copy');
    }

    // Finally we remove the textarea from the DOM
    document.body.removeChild(textArea);
  }

  // @HostListener('mouseenter') onMouseEnter(){ //SEE HERE
  //   this.element.style.color = 'blue';
  //   }

  constructor(elRef: ElementRef, private renderer: Renderer2) {
    this.element = elRef.nativeElement;
  }

  ngOnInit() {
    // console.log('Copy to Clipboard', this.element);
  }

  showTooltip() {
    // console.log('m mouseover')
    this.tooltip = this.renderer.createElement("span");
    // creating a span
    this.tooltip.appendChild(this.renderer.createElement("span"));
    // appending a span to the tooltip

    this.renderer.appendChild(
      this.tooltip,
      this.renderer.createText('Click to Copy')
      // adding the tooltip text into the tooltip span
    );
    const hostPos = this.element.getBoundingClientRect();
    // getting the hight width and positions of the target element
    let top, left;

    top = hostPos.bottom;
    // left = hostPos.left + hostPos.width / 2; // for center position
    left = hostPos.left;

    this.renderer.setStyle(this.tooltip, "top", `${top}px`);
    //adding a top positions value for the tooltip
    this.renderer.setStyle(this.tooltip, "left", `${left}px`);
    // adding the left value
    this.renderer.appendChild(document.body, this.tooltip);
   // appending to the document
    this.renderer.addClass(this.tooltip, "tooltip");
    this.renderer.addClass(this.tooltip, "badge");
    this.renderer.addClass(this.tooltip, "badge-info");
   // adding the tooltip styles
  }

}
