import { async } from "@angular/core/testing";
import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { ScraperService } from "src/app/services/scraper/scraper.service";
import * as moment from "moment-timezone";
import * as _ from "lodash";
import { urls } from "src/app/utils/url-utils";
import * as XLSX from "xlsx";
import { GeocodeService } from "src/app/services/geocode/geocode.service";
import { AngularFirestore, AngularFirestoreCollection } from "@angular/fire/firestore";
@Component({
  selector: "app-scraper",
  templateUrl: "./scraper.component.html",
  styleUrls: ["./scraper.component.css"],
})
export class ScraperComponent implements OnInit {
  title = "My first AGM project";
  boldData = [];
  ctcData = [];
  alcData = [];
  awtData = [];

  lat = 34.052235;
  lng = -118.243683;
  origin: any;
  destination: any;
  mapview = false;
  isTableView = false;
  otherMarkerFlag = true;
  boldMarkerFlag = true;
  ctcMarkerFlag = true;
  alcMarkerFlag = true;
  awtMarkerFlag = true;
  // Filters
  yellow = "http://maps.google.com/mapfiles/ms/icons/yellow-dot.png";
  blue = "http://maps.google.com/mapfiles/ms/icons/blue-dot.png";
  red = "http://maps.google.com/mapfiles/ms/icons/red-dot.png";
  pink = "http://maps.google.com/mapfiles/ms/icons/pink-dot.png";
  puCity = "";
  doCity = "";
  miles = "";
  amount = "";
  tripId = "";

  puCities = [];
  doCities = [];
  polyLineTrips = [];
  // puCity:any;
  // doCity:any;
  // miles:any;
  // payAmout:any;
  // tripId:any;

  // Dates
  preSelectedDate = moment(moment(), "YYYY-MM-DD").startOf("day").format();
  scheduleDate = moment(moment(), "YYYY-MM-DD").startOf("day").format();

  // Trips
  trips: any = [];
  filteredTrips: any = [];
  private itemsCollection: AngularFirestoreCollection<any>;

  constructor(
    private scraperService: ScraperService,
    private geocodeService: GeocodeService,
    private angularFirestore: AngularFirestore
  ) {
    this.itemsCollection = this.angularFirestore.collection<any>('trips');
    this.itemsCollection.valueChanges().subscribe(val => {
      console.log({ val })
      this.awtData = val;
    });;
  }

  ngOnInit() {
    this.getData();
  }

  addItem(item) {
    try {
      this.itemsCollection.add(item);
    } catch (error) {
      console.log(error)
    }
  }


  onDateChange(event) {
    // console.log('Change Event Value: ', event);
    const pickerDate = moment(event, "YYYY-MM-DD").utc(true).toISOString();
    // const pickerDate = moment(event, "YYYY-MM-DD").toISOString();
    if (event) {
      this.scheduleDate = pickerDate;
    }
    // console.log('scheduleDate: ', pickerDate);
  }

  getData() {
    this.scraperService.getData(this.scheduleDate).subscribe(
      (data) => {
        this.puCities = _.uniq(
          _.map(data, (item) => {
            return item.puCity !== "" && item.puCity;
          })
        ).sort();
        this.doCities = _.uniq(
          _.map(data, (item) => {
            return item.doCity !== "" && item.doCity;
          })
        ).sort();
        console.log("PU Cities: ", this.puCities);
        this.trips = data;
        this.filteredTrips = data;
        // console.log('RESPONSE DATA: ', this.trips);
      },
      (error) => {
        console.log("RESPONSE ERROR: ", error);
      }
    );
  }

  onSearch() {
    this.getData();
  }

  onPuCityChange(puCity) {
    // this.filteredTrips = this.trips.filter((trip: any) => trip.puCity.includes(puCity));
    this.listFilters();
  }

  onDoCityChange(doCity) {
    // this.filteredTrips = this.trips.filter((trip: any) => trip.doCity.includes(doCity));
    this.listFilters();
  }

  onMilesChange(miles) {
    // this.filteredTrips = this.trips.filter((trip: any) => trip.tripMiles <= miles);
    this.listFilters();
  }

  onAmountChange(amount) {
    console.log(amount);
    // if (amount) {
    //   // this.filteredTrips = this.trips.filter((trip: any) => trip.payAmount <= amount)
    // } else {
    //   // this.filteredTrips = this.trips;
    // }

    this.listFilters();
  }

  onTripIdChange(tripId) {
    // this.filteredTrips = this.trips.filter((trip: any) => trip.boltTripId == tripId);
    this.listFilters();
  }

  listFilters() {
    const trips = [];

    if (this.otherMarkerFlag) {
      const filterTrips = this.applyCityFilter(this.trips); // filter Trips
      trips.push(...filterTrips);
    }

    if (this.boldMarkerFlag) {
      const filterBoldTrips = this.applyCityFilter(this.boldData);
      trips.push(...filterBoldTrips);
    }

    if (this.ctcMarkerFlag) {
      const filterCTCTrips = this.applyCityFilter(this.ctcData);
      trips.push(...filterCTCTrips);
    }
    if (this.alcMarkerFlag) {
      const filterALCTrips = this.applyCityFilter(this.alcData);
      trips.push(...filterALCTrips);
    }

    if (this.awtMarkerFlag) {
      const filterAWTTrips = this.applyCityFilter(this.awtData);
      trips.push(...filterAWTTrips);
    }


    return trips;

  }

  applyCityFilter(myTrips) {
    if (this.puCity !== "") {
      myTrips = myTrips.filter((trip: any) =>
        trip.puCity.includes(this.puCity)
      );
    } else {
      myTrips = myTrips;
    }

    if (this.doCity !== "") {
      myTrips = myTrips.filter((trip: any) =>
        trip.doCity.includes(this.doCity)
      );
    } else {
      myTrips = myTrips;
    }



    if (this.tripId !== "") {
      myTrips = myTrips.filter(
        (trip: any) => trip.boltTripId == this.tripId
      );
    } else {
      myTrips = myTrips;
    }

    if (this.amount !== "") {
      myTrips = myTrips.filter(
        (trip: any) => trip.payAmount >= this.amount
      );
    } else {
      myTrips = myTrips;
    }

    if (this.miles !== "") {
      myTrips = myTrips.filter(
        (trip: any) => trip.tripMiles >= this.miles
      );
    } else {
      myTrips = myTrips;
    }


    return myTrips;

  }

  changeView() {
    this.mapview = !this.mapview;
  }

  changeTableView() {
    this.isTableView = !this.isTableView;
  }
  clearFilters() {
    this.puCity = "";
    this.doCity = "";
    this.miles = "";
    this.amount = "";
    this.tripId = "";
    this.filteredTrips = this.trips;
  }

  getDirection(puLat, puLon, doLat, doLon) {
    console.log("Hello  bhi sab");
    this.origin = { lat: puLat, lng: puLon };
    this.destination = { lat: doLat, lng: doLon };
  }

  download() {
    this.scraperService.downloadFile().subscribe(
      (data) => {
        if (data && data.name) {
          const a = document.createElement("a");
          a.href = urls.BASE_SERVER_URL + data.name;
          a.click();
        }
      },
      (error) => {
        console.log("DOWNLOAD ERROR: ", error);
      }
    );
  }

  onFileBoldChange(evt: any) {
    /* wire up file reader */
    const target: DataTransfer = <DataTransfer>evt.target;
    if (target.files.length !== 1) throw new Error("Cannot use multiple files");
    const reader: FileReader = new FileReader();
    reader.onload = async (e: any) => {
      /* read workbook */
      const bstr: string = e.target.result;
      const wb: XLSX.WorkBook = XLSX.read(bstr, { type: "binary" });

      /* grab first sheet */
      const wsname: string = wb.SheetNames[0];
      const ws: XLSX.WorkSheet = wb.Sheets[wsname];

      /* save data */
      const result = XLSX.utils.sheet_to_json(ws);

      for (let i = 0; i < result.length; i++) {
        const locationPickup = await this.scraperService
          .getCoordsFromServer(result[i]["Pickup Address"])
          .toPromise();
        const locationDropoff = await this.scraperService
          .getCoordsFromServer(result[i]["Dropoff Address"])
          .toPromise();
        console.log(result[i]);

        const item = {
          boltTripId: "BOLD TRIP",
          puAddress: result[i]["Pickup Address"],
          puLat: locationPickup.location.latitude,
          puLon: locationPickup.location.longitude,
          puImageUrl: this.getImageOfTripMap(
            locationPickup.location.latitude,
            locationPickup.location.longitude
          ),
          dueTime: moment(
            new Date(
              this.parseDateExcel(result[i]["Pickup Time"])
            ).toISOString()
          ).format("HH:mm"),
          doAddress: result[i]["Dropoff Address"],
          doLat: locationDropoff.location.latitude,
          doLon: locationDropoff.location.longitude,
          doImageUrl: this.getImageOfTripMap(
            locationDropoff.location.latitude,
            locationDropoff.location.longitude
          ),
          payAmount: result[i]["Pay Amount"],
          serviceLevel: result[i]["Service Level"],
          tripMiles: result[i]["Trip Miles"],
        };

        this.boldData.push(item);
        this.addItem(item)



        console.log("Trip Numer", i);
        // const timer = (ms) => new Promise((res) => setTimeout(res, ms));
        // await timer(2000);
      }

      console.log(this.boldData);
    };
    reader.readAsBinaryString(target.files[0]);
  }

  onFileCTCChange(evt: any) {
    /* wire up file reader */
    const target: DataTransfer = <DataTransfer>evt.target;
    if (target.files.length !== 1) throw new Error("Cannot use multiple files");
    const reader: FileReader = new FileReader();
    reader.onload = async (e: any) => {
      /* read workbook */
      const bstr: string = e.target.result;
      const wb: XLSX.WorkBook = XLSX.read(bstr, { type: "binary" });

      /* grab first sheet */
      const wsname: string = wb.SheetNames[0];
      const ws: XLSX.WorkSheet = wb.Sheets[wsname];

      /* save data */
      const result = XLSX.utils.sheet_to_json(ws);

      console.log(result);

      for (let i = 0; i < result.length; i++) {
        const locationPickup = await this.scraperService
          .getCoordsFromServer(
            `${result[i]["Origin Street"]}, ${result[i]["Origin City"]}, ${result[i]["Origin State"]}`
          )
          .toPromise();
        const locationDropoff = await this.scraperService
          .getCoordsFromServer(
            `${result[i]["Destination Street"]}, ${result[i]["Destination City"]}, ${result[i]["Destination State"]}`
          )
          .toPromise();
        console.log(result[i]);




        const item = {
          boltTripId: "CTC TRIP",
          puAddress: `${result[i]["Origin Street"]}, ${result[i]["Origin City"]}, ${result[i]["Origin State"]}`,
          puCity: result[i]["Origin City"],
          puLat: locationPickup.location.latitude,
          puLon: locationPickup.location.longitude,
          puImageUrl: this.getImageOfTripMap(
            locationPickup.location.latitude,
            locationPickup.location.longitude
          ),
          dueTime: moment(
            new Date(
              this.parseDateExcel(result[i]["Pickup Time"])
            ).toISOString()
          ).format("HH:mm"),
          doAddress: `${result[i]["Destination Street"]}, ${result[i]["Destination City"]}, ${result[i]["Destination State"]}`,
          doCity: result[i]["Destination City"],
          doLat: locationDropoff.location.latitude,
          doLon: locationDropoff.location.longitude,
          doImageUrl: this.getImageOfTripMap(
            locationDropoff.location.latitude,
            locationDropoff.location.longitude
          ),
          payAmount: result[i]["PRICE"],
          serviceLevel: result[i]["Type Of Trip"],
          tripMiles: result[i]["Miles"],
        };

        this.ctcData.push(item);
        this.addItem(item)

        //   console.log("Trip Numer", i);
        //   // const timer = (ms) => new Promise((res) => setTimeout(res, ms));
        //   // await timer(2000);
      }
      this.puCities.push(
        ..._.uniq(
          _.map(this.alcData, (item) => {
            return item.puCity !== "" && item.puCity;
          })
        ).sort()
      );

      this.doCities.push(
        ..._.uniq(
          _.map(this.alcData, (item) => {
            return item.doCity !== "" && item.doCity;
          })
        ).sort()
      );
      console.log(this.ctcData);
    };
    reader.readAsBinaryString(target.files[0]);
  }
  onFileAWTChange(evt: any) {
    /* wire up file reader */
    const target: DataTransfer = <DataTransfer>evt.target;
    if (target.files.length !== 1) throw new Error("Cannot use multiple files");
    const reader: FileReader = new FileReader();
    reader.onload = async (e: any) => {
      /* read workbook */
      const bstr: string = e.target.result;
      const wb: XLSX.WorkBook = XLSX.read(bstr, { type: "binary" });

      /* grab first sheet */
      const wsname: string = wb.SheetNames[0];
      const ws: XLSX.WorkSheet = wb.Sheets[wsname];

      /* save data */
      const result = XLSX.utils.sheet_to_json(ws);

      console.log(result);

      for (let i = 0; i < result.length; i++) {

        console.log(result[i]["Date"] + ' ' + result[i]["Req Pickup"])

        const locationPickup = await this.scraperService
          .getCoordsFromServer(
            `${result[i]["Pickup Address"]}, ${result[i]["Pickup City"]}, ${result[i]["Pickup State"]}, ${result[i]["Pickup Zip"]}`
          )
          .toPromise();
        const locationDropoff = await this.scraperService
          .getCoordsFromServer(
            `${result[i]["Dropoff Address"]}, ${result[i]["Dropoff City"]}, ${result[i]["Dropoff State"]}, ${result[i]["Dropoff Zip"]}`
          )
          .toPromise();
        console.log(result[i]);
        console.log(locationPickup);
        console.log(locationDropoff);
        let hours = parseInt(result[i]["Req Pickup"].split(':')[0]);
        const isPM = result[i]["Req Pickup"].split(':')[1].split(' ')[1] === "PM";
        hours = (isPM && hours !== 12) ? hours + 12 : hours;
        hours = hours === 24 ? 0 : hours;
        const mins = result[i]["Req Pickup"].split(':')[1].split(' ')[0];

        const item = {
          boltTripId: "ALC TRIP - " + result[i]["Trip Id"],
          puAddress: `${result[i]["Pickup Address"]}, ${result[i]["Pickup City"]}, ${result[i]["Pickup State"]}, ${result[i]["Pickup Zip"]}`,
          puCity: result[i]["Pickup City"],
          puLat: locationPickup.location && locationPickup.location.latitude,
          puLon: locationPickup.location && locationPickup.location.longitude,
          puImageUrl:
            locationPickup.location &&
            this.getImageOfTripMap(
              locationPickup.location.latitude,
              locationPickup.location.longitude
            ),
          dueTime: moment(new Date(
            this.parseDateExcel(result[i]["Date"])
          ).toISOString())
            .set('hour', hours)
            .set('minute', mins)
            .format("HH:mm"),


          doAddress: `${result[i]["Dropoff Address"]}, ${result[i]["Dropoff City"]}, ${result[i]["Dropoff State"]}, ${result[i]["Dropoff Zip"]}`,
          doCity: result[i]["Dropoff City"],
          doLat: locationDropoff.location && locationDropoff.location.latitude,
          doLon: locationDropoff.location && locationDropoff.location.longitude,
          doImageUrl:
            locationDropoff.location &&
            this.getImageOfTripMap(
              locationDropoff.location.latitude,
              locationDropoff.location.longitude
            ),
          payAmount: result[i]["Charge"] ? result[i]["Charge"] :0,
          serviceLevel: result[i]["Space"],
          tripMiles: result[i]["Distance"] ?result[i]["Distance"] :0 ,
          status: result[i]["Type"]? result[i]["Type"]:'',
        };
        this.awtData.push(item);
        this.addItem(item)

      }
      this.puCities.push(
        ..._.uniq(
          _.map(this.awtData, (item) => {
            return item.puCity !== "" && item.puCity;
          })
        ).sort()
      );
      this.doCities.push(
        ..._.uniq(
          _.map(this.awtData, (item) => {
            return item.doCity !== "" && item.doCity;
          })
        ).sort()
      );

      console.log(this.awtData);
    };
    reader.readAsBinaryString(target.files[0]);
  }

  onFileALCChange(evt: any) {
    /* wire up file reader */
    const target: DataTransfer = <DataTransfer>evt.target;
    if (target.files.length !== 1) throw new Error("Cannot use multiple files");
    const reader: FileReader = new FileReader();
    reader.onload = async (e: any) => {
      /* read workbook */
      const bstr: string = e.target.result;
      const wb: XLSX.WorkBook = XLSX.read(bstr, { type: "binary" });

      /* grab first sheet */
      const wsname: string = wb.SheetNames[0];
      const ws: XLSX.WorkSheet = wb.Sheets[wsname];

      /* save data */
      const result = XLSX.utils.sheet_to_json(ws);

      console.log(result);

      for (let i = 0; i < result.length; i++) {
        const locationPickup = await this.scraperService
          .getCoordsFromServer(
            `${result[i]["Pickup Address"]}, ${result[i]["Pickup City"]}, ${result[i]["Pickup State"]}`
          )
          .toPromise();
        const locationDropoff = await this.scraperService
          .getCoordsFromServer(
            `${result[i]["Dropoff Address"]}, ${result[i]["Dropoff City"]}, ${result[i]["Dropoff State"]}`
          )
          .toPromise();
        console.log(result[i]);
        console.log(locationPickup);
        console.log(locationDropoff);



        const item = {
          boltTripId: "ALC TRIP - " + result[i]["Bolt Trip ID"],
          puAddress: `${result[i]["Pickup Address"]}, ${result[i]["Pickup City"]}, ${result[i]["Pickup State"]}`,
          puCity: result[i]["Pickup City"],
          puLat: locationPickup.location && locationPickup.location.latitude,
          puLon: locationPickup.location && locationPickup.location.longitude,
          puImageUrl:
            locationPickup.location &&
            this.getImageOfTripMap(
              locationPickup.location.latitude,
              locationPickup.location.longitude
            ),
          dueTime: moment(
            new Date(
              this.parseDateExcel(result[i]["Pickup Time"])
            ).toISOString()
          ).format("HH:mm"),
          doAddress: `${result[i]["Dropoff Address"]}, ${result[i]["Dropoff City"]}, ${result[i]["Dropoff State"]}`,
          doCity: result[i]["Dropoff City"],
          doLat: locationDropoff.location && locationDropoff.location.latitude,
          doLon: locationDropoff.location && locationDropoff.location.longitude,
          doImageUrl:
            locationDropoff.location &&
            this.getImageOfTripMap(
              locationDropoff.location.latitude,
              locationDropoff.location.longitude
            ),
          payAmount: result[i]["Pay Amount"],
          serviceLevel: result[i]["Service Level"],
          tripMiles: result[i]["Trip Miles"],
          status: result[i]["Status"],
        }
        this.awtData.push(item);
        this.addItem(item)
      }
      this.puCities.push(
        ..._.uniq(
          _.map(this.alcData, (item) => {
            return item.puCity !== "" && item.puCity;
          })
        ).sort()
      );
      this.doCities.push(
        ..._.uniq(
          _.map(this.alcData, (item) => {
            return item.doCity !== "" && item.doCity;
          })
        ).sort()
      );

      console.log(this.alcData);
    };
    reader.readAsBinaryString(target.files[0]);
  }

  addressToCoordinates(address) {
    const location = this.geocodeService.geocodeAddress(address).toPromise();
    return location;
  }

  parseDateExcel(serial) {
    if (serial === "" || typeof serial == "string") {
      return serial;
    }
    var utc_days = Math.floor(serial - 25569);
    var utc_value = utc_days * 86400;
    var date_info = new Date(utc_value * 1000);
    var fractional_day = serial - Math.floor(serial) + 0.0000001;
    var total_seconds = Math.floor(86400 * fractional_day);
    var seconds = total_seconds % 60;
    total_seconds -= seconds;
    var hours = Math.floor(total_seconds / (60 * 60));
    var minutes = Math.floor(total_seconds / 60) % 60;
    var date = new Date(
      date_info.getFullYear(),
      date_info.getMonth(),
      date_info.getDate(),
      hours,
      minutes,
      seconds
    );
    return date;
  }

  getAddress(puAddress, puCity, puState, puZip) {
    return `${puAddress ? puAddress : ""}${puCity ? ", " + puCity : ""}${puState ? ", " + puState : ""
      }${puZip ? ", " + puZip : ""}`;
  }

  getImageOfTripMap(lat, long) {
    console.log(
      `https://maps.googleapis.com/maps/api/staticmap?markers=icon:http://maps.google.com/mapfiles/ms/icons/green.png%7C${lat},${long}&size=500x500&maptype=satellite&key=AIzaSyC60nn_VZXJTxtmWuIB9SnHGU6USDb1LGo`
    );
    return `https://maps.googleapis.com/maps/api/staticmap?markers=icon:http://maps.google.com/mapfiles/ms/icons/green.png%7C${lat},${long}&size=500x500&maptype=satellite&key=AIzaSyC60nn_VZXJTxtmWuIB9SnHGU6USDb1LGo`;
  }

  drawPolyLine() {
    if (this.polyLineTrips.length) {
      this.polyLineTrips.length = 0;
      return;
    }

    if (this.otherMarkerFlag) {
      this.polyLineTrips.push(...this.filteredTrips);
      console.log(this.filteredTrips.length);
    }
    if (this.boldMarkerFlag) {
      this.polyLineTrips.push(...this.boldData);
      console.log(this.boldData.length);
    }
    if (this.ctcMarkerFlag) {
      this.polyLineTrips.push(...this.ctcData);
      console.log(this.ctcData.length);
    }

    if (this.alcMarkerFlag) {
      this.polyLineTrips.push(...this.alcData);
      console.log(this.alcData.length);
    }
    console.log(this.polyLineTrips.length);
  }
}
